import React from 'react';

export default function Catalog() {
  const handleCreateAd = () => {
    window.open('/staff/catalog/create', '_blank');
  };

  return (
    <div className="min-h-screen bg-white">
      <header className="bg-white shadow">
        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">Welcome to Catalog</h1>
            <button
              type="button"
              onClick={handleCreateAd}
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Create Ad
            </button>
          </div>
        </div>
      </header>
    </div>
  );
} 