import React from 'react';

export default function AdForm() {
  return (
    <div className="min-h-screen bg-white">
      <header className="bg-white shadow">
        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">Create New Ad</h1>
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          {/* Form will go here */}
          <div className="px-4 py-6 sm:px-0">
            <div className="rounded-lg border-4 border-dashed border-gray-200 p-4">
              <p className="text-gray-500">Form placeholder</p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
} 