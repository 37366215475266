import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';

const PrivateRoute = ({ children, userType }) => {
  const { isAuthenticated, userRole, loading } = useAuth();

  // console.log(isAuthenticated);
  // console.log(userRole);

  // Wait for authentication check to complete
  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    // If not logged in, redirect to appropriate login page
    switch (userType) {
      case 'student':
        return <Navigate to="/student/login" />;
      case 'staff':
        return <Navigate to="/staff/login" />;
      default:
        return <Navigate to="/tutor/login" />;
    }
  }

  // Check if user role matches the required type
  if (userType && userRole !== userType) {
    // Redirect to appropriate dashboard based on user role
    switch (userRole) {
      case 'tutor':
        return <Navigate to="/tutor/dashboard" />;
      case 'student':
        return <Navigate to="/student/dashboard" />;
      case 'staff':
        return <Navigate to="/staff/inbox" />;
      default:
        return <Navigate to="/" />;
    }
  }

  return children;
};

export default PrivateRoute;