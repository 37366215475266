import { useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const initialOptions = {
  "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
  currency: "USD",
  intent: "capture",
  components: "buttons"
};

export default function Payments() {
  const [buttonKey, setButtonKey] = useState(1);

  const handlePaymentSuccess = async (details) => {
    console.log('Payment Successful!', {
      paymentId: details.id,
      amount: details.purchase_units[0].amount.value,
      status: details.status,
      payerEmail: details.payer.email_address,
      paymentTime: details.create_time
    });
    
    alert('Payment successful! Thank you.');
    // Reset input value
    document.getElementById('amount').value = '';
    setButtonKey(prev => prev + 1);
  };

  const createOrder = (data, actions) => {
    const amountInput = document.getElementById('amount');
    const inputAmount = amountInput.value;
    
    console.log("Amount from input:", inputAmount);
    
    if (!inputAmount || parseFloat(inputAmount) <= 0) {
      alert('Please enter a valid amount');
      return Promise.reject('Invalid amount');
    }

    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: inputAmount,
            currency_code: "USD"
          },
        },
      ],
    });
  };

  const onApprove = async (data, actions) => {
    try {
      const details = await actions.order.capture();
      handlePaymentSuccess(details);
    } catch (error) {
      console.error('Payment capture failed:', error);
      alert('There was an error processing your payment.');
    }
  };

  const onError = (err) => {
    if (err && err.message !== 'Invalid amount') {
      console.error('PayPal Button Error:', err);
      alert('There was an error processing your payment.');
    }
  };

  return (
    <PayPalScriptProvider options={initialOptions}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <div className="flex flex-col items-center">
              <h3 className="text-lg font-medium leading-6 text-gray-900 mb-6">
                Make a Payment
              </h3>
              <div className="w-full max-w-md">
                <div className="mb-6">
                  <div className="relative">
                    <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 text-lg">
                      $
                    </span>
                    <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 text-lg">
                      USD
                    </span>
                    <input
                      type="number"
                      id="amount"
                      className="block w-full h-12 px-12 rounded-md 
                                border-2 border-gray-300 
                                bg-white
                                shadow-sm 
                                focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50
                                text-lg text-center
                                hover:border-gray-400
                                transition-colors duration-200
                                [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                      placeholder="Enter amount"
                      min="1"
                    />
                  </div>
                </div>

                <div className="paypal-button-container">
                  <PayPalButtons
                    key={buttonKey}
                    style={{
                      layout: "vertical",
                      color: "gold",
                      shape: "rect",
                      label: "paypal",
                      height: 48
                    }}
                    createOrder={createOrder}
                    onApprove={onApprove}
                    onError={onError}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PayPalScriptProvider>
  );
} 