import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { staffApi } from '../staffApi';
import { CHAT_LIST_POLLING_INTERVAL, ACTIVE_CHAT_POLLING_INTERVAL } from '../../../constants';
import DetailsPanel from './components/DetailsPanel';
import ChatList from './components/ChatList';
import ChatMessages from './components/ChatMessages';

function Inbox() {
    const [selectedChat, setSelectedChat] = useState(null);
    const [newMessage, setNewMessage] = useState('');
    const [chatrooms, setChatrooms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [messages, setMessages] = useState([]);
    const [loadingMessages, setLoadingMessages] = useState(false);
    const [messageError, setMessageError] = useState(null);
    const [sendingMessage, setSendingMessage] = useState(false);
    const [mobileView, setMobileView] = useState('list'); // 'messages', 'list', or 'details'
    

    useEffect(() => {
        return () => {
            // Cleanup function to clear intervals when component unmounts
            if (window.chatListInterval) clearInterval(window.chatListInterval);
            if (window.messageInterval) clearInterval(window.messageInterval);
        };
    }, []);

    // First define fetchMessages without any dependencies
    const fetchMessages = useCallback(async (chatroomId, chatName) => {
        if (!chatroomId) return;
        
        try {
            setLoadingMessages(true);
            const response = await staffApi.getChatroomMessages(chatroomId);

            // Handle null, empty array, or empty object response.data
            if (!response?.data || 
                response.data.length === 0 || 
                Object.keys(response.data).length === 0) {
                console.log('No messages found');
                setMessages([]);
                setMessageError('');
                return;
            }

            const formattedMessages = response.data.map(message => ({
                id: message.id,
                text: message.content,
                createdAt: message.timestamp,
                user: {
                    id: message.sender_name === chatName ? 'tutor' : 'student',
                    name: message.sender_name
                },
                isRead: message.is_read,
                readAt: message.read_at
            }));
            setMessages(formattedMessages);
            setMessageError('');
        } catch (err) {
            console.error('Failed to fetch messages:', err);
            setMessageError('Failed to load messages');
        } finally {
            setLoadingMessages(false);
        }
    }, []); // No dependencies needed

    const fetchChatrooms = useCallback(async () => {
        try {
            setLoading(true);
            const response = await staffApi.getChatrooms();
            const formattedChats = response.results.map(chat => ({
                id: chat.id,
                name: chat.other_user_name,
                lastMessage: chat.last_message ? chat.last_message.content : 'No messages yet',
                unread: chat.unread_count,
                role: 'tutor',
                subject: chat.prospect_details?.subject,
                grade: chat.prospect_details?.grade,
                notes: chat.prospect_details?.notes,
                demoDate: chat.prospect_details?.demo_date,
                demoTime: chat.prospect_details?.demo_time,
                timezone: chat.prospect_details?.timezone,
                dateTime: new Date(chat.created_at).toLocaleDateString(),
                leads: chat.leads || null
            }));
            
            setChatrooms(formattedChats);
            
            // If selected chat exists, update its data
            if (selectedChat) {
                const updatedSelectedChat = formattedChats.find(chat => chat.id === selectedChat.id);
                if (updatedSelectedChat) {
                    setSelectedChat(updatedSelectedChat);
                }
            }
            // If no chat is selected and we have chats, select the first one
            else if (formattedChats.length > 0 && !selectedChat) {
                setSelectedChat(formattedChats[0]);
            }
        } catch (err) {
            console.error('Failed to fetch chatrooms:', err);
            setError('Failed to load conversations');
        } finally {
            setLoading(false);
        }
    }, [selectedChat]); 

    // Initial load effect - simplified
    useEffect(() => {
        fetchChatrooms();
    }, []);

    // Chat list polling effect
    useEffect(() => {
        const pollInterval = setInterval(fetchChatrooms, CHAT_LIST_POLLING_INTERVAL);
        return () => clearInterval(pollInterval);
    }, [fetchChatrooms]);

    // Messages polling effect
    useEffect(() => {
        if (!selectedChat) return;

        // Initial fetch
        fetchMessages(selectedChat.id, selectedChat.name);

        // Set up polling
        const pollInterval = setInterval(() => {
            fetchMessages(selectedChat.id, selectedChat.name);
        }, ACTIVE_CHAT_POLLING_INTERVAL);

        return () => clearInterval(pollInterval);
    }, [selectedChat, fetchMessages]);

    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (!newMessage.trim() || !selectedChat) return;
        
        try {
            setSendingMessage(true);
            await staffApi.sendMessage(selectedChat.id, newMessage.trim());
            setNewMessage('');
            await fetchMessages(selectedChat.id, selectedChat.name);
        } catch (error) {
            console.error('Failed to send message:', error);
        } finally {
            setSendingMessage(false);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage(e);
        }
    };

    const handleMessageChange = (e) => {
        setNewMessage(e.target.value);
    };

    // Add this effect to handle view changes when chat is selected/deselected
    useEffect(() => {
        if (selectedChat) {
            setMobileView('messages');
        } else {
            setMobileView('list');
        }
    }, [selectedChat]);

    return (
        <Container>
            <ChatListColumn $visible={mobileView === 'list'}>
                <ChatList 
                    chatrooms={chatrooms}
                    selectedChat={selectedChat}
                    onChatSelect={(chat) => {
                        setSelectedChat(chat);
                    }}
                    loading={loading}
                    error={error}
                />
            </ChatListColumn>

            <ChatColumn $visible={mobileView === 'messages'}>
                <MobileHeader>
                    {selectedChat && (
                        <BackButton onClick={() => setMobileView('list')}>
                            ← Back
                        </BackButton>
                    )}
                    {selectedChat && (
                        <HeaderTitle>
                            {selectedChat.name}
                            <DetailsButton onClick={() => setMobileView('details')}>
                                Details →
                            </DetailsButton>
                        </HeaderTitle>
                    )}
                </MobileHeader>

                <ChatMessages 
                    selectedChat={selectedChat}
                    messages={messages}
                    loadingMessages={loadingMessages}
                    messageError={messageError}
                    newMessage={newMessage}
                    sendingMessage={sendingMessage}
                    onMessageChange={handleMessageChange}
                    onKeyDown={handleKeyDown}
                    onSendMessage={handleSendMessage}
                />
            </ChatColumn>

            <DetailsColumn $visible={mobileView === 'details'}>
                {selectedChat && (
                    <MobileHeader>
                        <BackButton onClick={() => setMobileView('messages')}>
                            ← Back
                        </BackButton>
                    </MobileHeader>
                )}
                <DetailsPanel selectedChat={selectedChat} />
            </DetailsColumn>
        </Container>
    );
}

// Updated and new styled components
const Container = styled.div`
    display: grid;
    grid-template-columns: 280px 1fr 280px;
    height: calc(100vh - 80px);
    background: #fff;
    gap: 1px;
    background-color: #e0e0e0;
    overflow: hidden;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const ChatListColumn = styled.div`
    background: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;

    @media (max-width: 768px) {
        display: ${props => props.$visible ? 'flex' : 'none'};
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
    }
`;

const ChatColumn = styled.div`
    background: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    @media (max-width: 768px) {
        display: ${props => props.$visible ? 'flex' : 'none'};
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
    }
`;

const DetailsColumn = styled.div`
    background: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 3px;
    }

    @media (max-width: 768px) {
        display: ${props => props.$visible ? 'flex' : 'none'};
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
    }
`;

const MobileHeader = styled.div`
    display: none;
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
    background: #fff;

    @media (max-width: 768px) {
        display: flex;
        align-items: center;
        gap: 10px;
    }
`;

const BackButton = styled.button`
    padding: 8px 12px;
    border: none;
    background: none;
    color: #007bff;
    cursor: pointer;
    font-weight: bold;
`;

const HeaderTitle = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
`;

const DetailsButton = styled.button`
    padding: 8px 12px;
    border: none;
    background: none;
    color: #007bff;
    cursor: pointer;
    font-weight: bold;
`;

export default Inbox;