import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useGATracker = () => {
  const location = useLocation();

  useEffect(() => {
    if (typeof window.gtag === 'function') {
      // Send pageview
      window.gtag('event', 'page_view', {
        page_title: document.title,
        page_location: window.location.href,
        page_path: location.pathname + location.search,
      });

      // Navigation event
      window.gtag('event', 'page_navigation', {
        event_category: 'Navigation',
        event_label: location.pathname
      });

      console.log('Tracking page:', location.pathname);
    }
  }, [location]);
};

export default useGATracker; 