import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { UserCircleIcon } from '@heroicons/react/24/outline'
import { USER_INFO } from '../../constants'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function UserMenu({ userType }) {
  const userInfo = JSON.parse(localStorage.getItem(USER_INFO))
  const firstName = userInfo?.first_name || ''

  const handleLogout = () => {
    localStorage.removeItem(USER_INFO)
    if (userType === 'student') {
      localStorage.removeItem('studentToken')
    } else if (userType === 'tutor') {
      localStorage.removeItem('tutorToken')
    } else if (userType === 'staff') {
      localStorage.removeItem('staffToken')
    }
    window.location.href = '/'
  }

  return (
    <Menu as="div" className="relative ml-3">
      <div>
        <Menu.Button className="flex items-center max-w-xs rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          <span className="sr-only">Open user menu</span>
          <UserCircleIcon className="h-8 w-8 text-gray-400" aria-hidden="true" />
          <span className="ml-3 text-sm font-medium text-gray-700">
            {firstName}
          </span>
          <ChevronDownIcon className="ml-2 h-4 w-4 text-gray-400" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <a
                href={`/${userType}/dashboard`}
                className={classNames(
                  active ? 'bg-gray-100' : '',
                  'block px-4 py-2 text-sm text-gray-700'
                )}
              >
                Dashboard
              </a>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={handleLogout}
                className={classNames(
                  active ? 'bg-gray-100' : '',
                  'block w-full text-left px-4 py-2 text-sm text-gray-700'
                )}
              >
                Sign out
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  )
} 